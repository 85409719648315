import Vue from 'vue';
export const state = Vue.observable({
  currentRoute: null,
  changeType: 'common'
});

export const mutations = {
    detectRoute(data) {
    state.currentRoute = data;
  },
  setChangeType(data) {
    state.changeType = data;
  },
};